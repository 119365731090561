import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled as styled_mui } from "@mui/material/styles";
import styled from "styled-components";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Select from "react-select";
import Box from "@mui/system/Box";
import AddIcon from "@material-ui/icons/Add";
import SwtAlert from "../../../../Utils/SwtAlert";

const ContainerBox = styled.div`
  .font-style {
    font-family: "Poppins";
    letter-spacing: 0.005em;
  }
  .create-btn {
    background: #123fbc;
    color: white;
    width: fit-content;
    padding: 20px 17px;
    height: 35px;
    border-radius: 11px;
  }
  .create-btn:hover {
    background: #123fbc;
    color: white;
  }
`;

const CustomDialog = styled.div`
  .disable-hover:hover {
    border: 1px solid #123fbc;
  }
  .proceed_button_create {
    width: 180px;
    font-size: 14px;
    font-family: "Poppins";
    font-weight: 600;
    letter-spacing: 0.005em;
    color: white;
    background-color: #123fbc;
    float: right;
    margin: 70px 25px 50px auto;
  }
  .proceed_button_create:hover {
    color: white;
    background-color: #123fbc;
  }

  .custom-history-input {
    height: 38px;
    width: 250px;
    border-radius: 4px;
    font-size: 14px;
    background-color: #f8f8f8;
    box-sizing: border-box;
    position: fixed;
  }

  .dropdown_1,
  .dropdown_2 {
    position: relative;
    z-index: 1;
    display: inline-block;
  }

  .dropdown_2 {
    margin-left: 270px;
  }

  .fg-white {
    color: white;
  }

  .fg-black {
    color: #3d3d3d;
  }

  .font-14 {
    font-size: 14px;
  }

  .font-18 {
    font-size: 18px;
  }

  .bold-600 {
    font-weight: 600;
  }

  .bold-400 {
    font-weight: 400;
  }

  @media only screen and (max-width: 650px) {
    .dropdown_1,
    .dropdown_2 {
      display: block;
    }

    .dropdown_2 {
      margin-left: 190px;
    }

    .custom-history-input {
      display: block;
      width: 180px;
    }
  }
`;
const BootstrapDialog = styled_mui(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const FormSelect = styled(Select)`
  width: 150px;
  padding: 0;
  border-radius: 4px;
  margin: 0px 20px 0px 20px;
  width: 200px;
  align-content: center;
  > div {
    margin-left: 0;
    background-color: #f8f8f8;
    border: 1px solid rgba(0, 0, 0, 0.23);
  }
`;

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;
  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const CreatePrescreenPopup = ({
  roleResponse,
  roleOptions,
  dataLength,
  defaultRole,
  defaultCategory,
  client_id,
}) => {
  const [open, setOpen] = React.useState(false);
  const [role, setRole] = React.useState(defaultRole);

  React.useEffect(() => {
    setRole(defaultRole);
 
  }, [defaultRole, defaultCategory]);

  const roleChangeHandle = (option) => {
    const { value } = option;
    if (value?.length !== 0 && roleResponse[value] !== undefined) {
      setRole(value);
    }
  };

  const handleProceed = () => {
    if (!role || role?.length === 0) {
      SwtAlert("Please select Role", 1000, "error", false);
    } else if (role?.length !== 0 ) {
      const url_client_id = encodeURIComponent(client_id);
      const url_role_name = encodeURIComponent(role);
      window.location.href = `/quickscreen/add?type=new&client_id=${url_client_id}&role_name=${url_role_name}`;
    } else {
      SwtAlert("Some error occurred", 2000, "error", false);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <ContainerBox
      className="create_prescreen_popup_main_container"
      style={{ display: "inline-block" }}
    >
      {dataLength && dataLength?.length !== 0 ? (
        <a
          style={{
            fontSize: "16px",
            textDecoration: "underline",
            cursor: "pointer",
            color: "blue",
          }}
          onClick={handleClickOpen}
        >
          {dataLength}
        </a>
      ) : (
        <Button
          variant="outlined"
          className="create-btn"
          onClick={handleClickOpen}
        >
          <AddIcon /> Create test
        </Button>
      )}
      <BootstrapDialog
        PaperProps={{
          style: { height: "300px" },
        }}
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="sm"
        fullWidth
        className="font-style"
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
          className="font-18 bold-600 fg-black"
        >
          Create New Test
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <CustomDialog>
            <Typography gutterBottom className="font-14 bold-400 fg-black">
              Choose a role for which you want to create the QuickScreen test.
            </Typography>
            <Box marginTop="20px">
              <Box className="dropdown_1">
                <FormSelect
                  className="custom-history-input"
                  options={roleOptions}
                  placeholder="Role"
                  onChange={roleChangeHandle}
                  name="selectRole"
                  isSearchable
                  value={role ? { label: role, value: role } : ""}
                />
              </Box>
            </Box>

            <Typography gutterBottom>
              <Button className="proceed_button_create" onClick={handleProceed}>
                Proceed
              </Button>
            </Typography>
          </CustomDialog>
        </DialogContent>
      </BootstrapDialog>
    </ContainerBox>
  );
};
export default CreatePrescreenPopup;
