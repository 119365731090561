import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";

import "./Preview.scss";
import Box from '@mui/material/Box';
import Typography from "@mui/material/Typography";
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import logoiv from "../../../../assets/images/logo-iv.png";
import copy from "../../../../assets/images/copy.svg";
import rightTick from "../../../../assets/images/right_tick.svg";
import TaskAlt from '@mui/icons-material/TaskAlt';
import moment from "moment-timezone";
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { PRESCREEN_TOPIC, OPTIONS_LABEL } from "../Constants";
const INTEGER = "INT_OUTPUT_BASED";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#F3F5FA",
    color: "#3D3D3D",
    fontSize: "14px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "12px",
  },
  fontFamily: "Poppins",
}));

const StyledTableCellTopic = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#F3F5FA",
    color: "#3D3D3D",
    fontSize: "14px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "12px",
  },
  [`& .head-txt`] : {
    fontFamily: "Poppins",
    color:"#123FBC",
    fontSize:"14px",
    fontWeight:"600",
    textTransform:"capitalize"
  },
  borderBottom:"none",
  padding:"0px",
  paddingLeft:"16px",
  paddingTop:"16px",
}));

const hideBorder = {
  borderBottom:"none"
}

const Preview = ({ dataPreview, previewShareLinkData, copyLinkVisibility = false, getShareableLinkData }) => {
  const { search } = useLocation();
  const parsedQueryString = queryString.parse(search);
  const id = parsedQueryString?.id;
  const [prescreenData, setPrescreenData] = useState(dataPreview);
  const [shareLinkCopied, setShareLinkCopied] = useState({ open: false, vertical: 'top', horizontal: 'center' });
  const { vertical, horizontal, open } = shareLinkCopied;
  const [prescreenQuestion,setPrescreenQuestion] = useState();

  useEffect(() => {
    if (id) setPrescreenData(dataPreview);
  }, [dataPreview]);

  useEffect(()=>{
    if(prescreenData?.questions)
    {
      const addedQuestionBankList = prescreenData?.questions;
      const topics = {};
      addedQuestionBankList?.map(question=>{
        if(question?.topic && question?.topic?.length > 0)
        {
          if(!topics.hasOwnProperty(question?.topic))
          {
            topics[`${question?.topic}`] = []
          }
            if(!topics[`${question?.topic}`] || topics[`${question?.topic}`]?.length === 0)
            {
              topics[`${question?.topic}`] = [question];
            }
            else
            {
              topics[`${question?.topic}`]?.push(question);
            }
        }
        else
        {
          if(!topics.hasOwnProperty(PRESCREEN_TOPIC.OTHERS))
          {
            topics[`${PRESCREEN_TOPIC.OTHERS}`] = []
          }
          if(!topics[`${PRESCREEN_TOPIC.OTHERS}`] || topics[`${PRESCREEN_TOPIC.OTHERS}`]?.length === 0)
          {
            topics[`${PRESCREEN_TOPIC.OTHERS}`] = [question];
          }
          else
          {
            topics[`${PRESCREEN_TOPIC.OTHERS}`]?.push(question);
          }
        }
      });
      setPrescreenQuestion(topics)
         }
  },[prescreenData])

  const handleShareableLink = () => {
    if (previewShareLinkData?.length === 0) {
      getShareableLinkData().then((res) => {
        navigator.clipboard.writeText(res?.data?.pre_screening_access_url).then(() => {
          setShareLinkCopied({ open: true, vertical: 'top', horizontal: 'center' });
        })
      })
    }

    if (!navigator.clipboard) {
      return;
    }
    navigator.clipboard.writeText(previewShareLinkData).then(() => {
      setShareLinkCopied({ open: true, vertical: 'top', horizontal: 'center' });
    })
  }

  const  QuestionTable = (data) => {
    const getDefaultSelected = [];

    data?.answer_json?.map((answer, index) => {
      const defaultSelected = answer === "OPTION_ONE" ? 1
        : answer === "OPTION_TWO" ? 2
          : answer === "OPTION_THREE" ? 3
            : answer === "OPTION_FOUR" ? 4
              : answer === "OPTION_FIVE" ? 5
                : answer === "OPTION_SIX" ? 6
                  : answer === "OPTION_SEVEN" ? 7
                    : answer === "OPTION_EIGHT" ? 8
                      : answer === "OPTION_NINE" ? 9
                        : answer === "OPTION_TEN" ? 10
                          : 1;

      getDefaultSelected.push(defaultSelected)
    })

    const optionArray = [];
    if (data?.options_json?.option1) {
      optionArray.push(data?.options_json?.option1)
    }
    if (data?.options_json?.option2) {
      optionArray.push(data?.options_json?.option2)
    }
    if (data?.options_json?.option3) {
      optionArray.push(data?.options_json?.option3)
    }
    if (data?.options_json?.option4) {
      optionArray.push(data?.options_json?.option4)
    }
   if (data?.options_json?.option5) {
      optionArray.push(data?.options_json?.option5)
    }
    if (data?.options_json?.option6) {
      optionArray.push(data?.options_json?.option6)
    }
    if (data?.options_json?.option7) {
      optionArray.push(data?.options_json?.option7)
    }
    if (data?.options_json?.option8) {
      optionArray.push(data?.options_json?.option8)
    }
    if (data?.options_json?.option9) {
      optionArray.push(data?.options_json?.option9)
    }
    if (data?.options_json?.option10) {
      optionArray.push(data?.options_json?.option10)
    }

    const responseStatus = {
      option1: "OPTION_ONE",
      option2: "OPTION_TWO",
      option3: "OPTION_THREE",
      option4: "OPTION_FOUR",
      option5: 'OPTION_FIVE',
      option6: 'OPTION_SIX',
      option7: 'OPTION_SEVEN',
      option8: 'OPTION_EIGHT',
      option9: 'OPTION_NINE',
      option10: 'OPTION_TEN',
};

    return (
      <>
        <TableContainer sx={{border:"1px solid #D6D9E2", borderRadius:"14px"}} className="tableContainer">
          <Table aria-label="customized table">
            <TableHead>
            <TableRow className="questionsHeader">
              <StyledTableCell width="50%">Questions</StyledTableCell>
              <StyledTableCell align="right" width="18%">Difficulty <br/>Level</StyledTableCell>
              <StyledTableCell align="right" className="positive">Positive <br/>Points</StyledTableCell>
              {
                prescreenData?.negative_marking?
                 <StyledTableCell align="right">Negative <br/>Points</StyledTableCell>
                :null
              }
            </TableRow>
            </TableHead>
            <TableBody>
              {
               prescreenQuestion && Object.entries(prescreenQuestion)?.map(([topic,questions],i)=>{
                  return questions?.map((data,index) => {
                        return <>
                                 {
                                      index === 0 ? 
                                      <TableRow>
                                        <StyledTableCellTopic component="th" scope="row" width="58%" >
                                          <Typography className="head-txt">{topic === PRESCREEN_TOPIC.OTHERS?"Generic Questions":topic}</Typography>
                                        </StyledTableCellTopic> 
                                      </TableRow>
                                      : null
                                    }
                         <TableRow key={index}>
                                 
                    <StyledTableCell sx={index !== questions?.length - 1 ? hideBorder :''} component="th" scope="row" className="styledTabCellQstn" >
                                      <Box display='flex'>
                                        <Box className="question-text">
                                          <Box maxWidth="70px" minWidth="30px"> Q{index + 1}.</Box>
                                          <Box className="quill-box" dangerouslySetInnerHTML={{__html: data?.question_text || ""}}></Box>
                                        </Box>
                                      </Box>

                                      <Box display='flex' >
                                        {data?.tags && <div className="question-tags"> {data?.tags?.join(", ")}</div>}
                                      </Box>
                    
                                      {data?.question_code && <Box>
                                        <Box className="question-code">
                                          {data?.question_code}&nbsp;
                                        </Box>
                                      </Box>}

                                      {data?.question_image_view_url &&
                                      <Box className='image-box'>
                                        <img src={data?.question_image_view_url} className="image-style" alt='question-image' />
                                      </Box>}

                              <Box className='option-box'>
                                {data?.type === INTEGER ?
                                  <Box className="ml-20">
                                    Answer = {data?.answer_json[0]}
                                  </Box>
                                  :
                                  <Typography>
                                    <ul className='options-text'>
                                    {Object.entries(data?.options_json)?.map(([option, value]) => {
                                      return (
                                        <li><pre>{value}{data?.answer_json?.includes(OPTIONS_LABEL[option]) && <img src={rightTick} className="right-tick" />}</pre></li>
                                      )
                                    })}
                                    </ul>
                                  </Typography>
                                }
                              </Box>
                    
                                      <Box display='flex' >
                                        {data?.tags && <div className="question-tags"> {data?.tags?.join(", ")}</div>}
                                      </Box>

                                      <Box>
                                      {optionArray?.map((data, index) => {
                                        return (<Box dsplay='flex' flexDirection={'row'}>
                                          <div>
                                            <span className={`configurations-label ml-2 p-1 ${getDefaultSelected?.find((data) => data === index + 1) ? 'selected-answer' : ''}`}>Option {index + 1}. {data}</span>
                                            <span className="ml-10 correct-answer">{getDefaultSelected?.find((data) => data === index + 1) &&
                                          <> <TaskAlt/> Correct Answer</>}</span>
                                          </div>
                                        </Box>)
                                      })}
                                      </Box>
                                    </StyledTableCell>
                                    <StyledTableCell sx={index !== questions?.length - 1 ? hideBorder :''} align="right" width="18%">
                                      {data?.difficulty_level && <>
                                        <span className={`difficulty ${data?.difficulty_level?.toLowerCase() === 'easy' ? 'fg-green' : data?.difficulty_level?.toLowerCase() === 'medium' ? 'fg-gold' : 'fg-red'}`}>
                                          {data?.difficulty_level?.toUpperCase()=="EASY"? "Easy" : data?.difficulty_level?.toUpperCase()=="MEDIUM"? "Medium" : "Hard" }
                                        </span>
                                      </>}
                                    </StyledTableCell>
                                    <StyledTableCell sx={index !== questions?.length - 1 ? hideBorder :''} align="right" className="font-600">{data?.positive_mark}</StyledTableCell>
                                    {
                                      prescreenData?.negative_marking? 
                                      <StyledTableCell sx={index !== questions?.length - 1 ? hideBorder :''} align="right" className="font-600">{data?.negative_mark}</StyledTableCell>
                                      :null
                                    }
                                  </TableRow>
                                  </>
                                      })
                })
              }
            
            </TableBody>
          </Table>
        </TableContainer>
      </>
    )
  }

  if (!prescreenData) {
    return <></>;
  }

  return (
    <div className="prescreen_preview_box">
      <Box display="flex" justifyContent="space-between" className="preview_topBar">
        <img src={logoiv} className="iv-logo" />
        <div className="navbar-text">QUICKSCREEN TEST</div>
        {copyLinkVisibility ? <div className="share-link" onClick={handleShareableLink}>
          <img src={copy} height="20px" width="20px" /> <p>Copy Link</p>
        </div> : <div></div>}
        {/* ----- Link copied popup ----*/}
        {shareLinkCopied?.open && <Snackbar anchorOrigin={{ vertical, horizontal }} autoHideDuration={1000}
          open={open} onClose={() => { setShareLinkCopied({ ...shareLinkCopied, open: false }); }} >
          <SnackbarContent style={{ backgroundColor: 'green', marginTop: "30px", fontWeight: '600' }}
            message={<span id="client-snackbar">✅ Link copied to clipboard</span>} />
        </Snackbar>}
      </Box>

      <div className="prescreen_preview_main_container">
        <div className="prescreen_preview_container">
          <div>
            <p className="preview_title">{prescreenData?.title}</p>
            <p className="preview_role">{prescreenData?.role}</p>
          </div>
          <hr />

          <div className="prescreen_preview_details">

            <h2 className="h2-tag">Test Configurations</h2>

            <Box display='flex'>
              <div className="configurations-label">Total time allowed for each test:</div>
              <div className="configurations-value">{prescreenData?.time_allotted} mins</div>
            </Box>

            <Box display='flex'>
              <div className="configurations-label">Number of questions to ask each candidate:</div>
              <div className="configurations-value">{prescreenData?.number_of_questions}</div>
            </Box>

           { prescreenData?.test_validity &&  <Box display='flex'>
              <div className="configurations-label">Test link validity:</div>
              <div className="configurations-value">{prescreenData?.test_validity} {prescreenData?.test_validity > 1 ? 'days' : 'day'} </div>
            </Box>}

           { prescreenData?.start_timestamp && <Box display='flex'>
              <div className="configurations-label">Test link start timestamp:</div>
              <div className="configurations-value">{moment.unix(prescreenData?.start_timestamp).format("MMM DD, hh:mm a")} </div>
            </Box> }
           { prescreenData?.time_window && <Box display='flex'>
              <div className="configurations-label">Time Window:</div>
              <div className="configurations-value">{prescreenData?.time_window} {prescreenData?.test_validity > 1 ? 'mins' : 'min'} </div>
            </Box>}
           { prescreenData?.last_allowed_submission_timestamp && <Box display='flex'>
              <div className="configurations-label">Test link last allowed timestamp :</div>
              <div className="configurations-value">{moment.unix(prescreenData?.last_allowed_submission_timestamp).format("MMM DD, hh:mm a")} </div>
            </Box>}

            <h4 className="h4-tag">Passing Criteria</h4>

            <Box display='flex'>
              <div className="configurations-label">Cut off for screening candidates:</div>
              <div className="configurations-value">{prescreenData?.cut_off_percentage}%</div>
            </Box>

            <Box display='flex'>
              <Box className="configurations-label">Max. Tab Switch Count:</Box>
              <Box className="configurations-value"  marginTop={"2px"}>{prescreenData?.tab_switch_check?.count || 0}</Box>
            </Box>

            <Box display='flex'>
              <Box className="configurations-label">Negative marking: </Box>
              <Box className="configurations-value" marginTop={"2px"}>{prescreenData?.negative_marking ? "Enabled" : "Disabled"}</Box>
            </Box>
           
            <hr />

            <h2 className="h2-tag">Question Set</h2>

            {prescreenData?.questions && 
              <QuestionTable  prescreenQuestionData={prescreenData?.questions}/>
            }

          </div>
        </div>
      </div>
    </div>
  );
};

export default Preview;
