import { useState, useEffect } from "react";
import { useHistory } from "react-router";
import "./PrescreenForm.scss";

import { FormAction, StepperSteps } from "../Constants";
import Stepper from "./Stepper";
//Importing Form
import TestConfigurations from "./Sections/TestConfigurations";
import QuestionSet from "./Sections/QuestionSet";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from '@mui/material/TextField';

import ArrowBack from "@material-ui/icons/ArrowBackIos";
import ArrowBackOutlined from "@material-ui/icons/ArrowBackOutlined";
import ArrowForwardOutlined from "@material-ui/icons/ArrowForwardOutlined";
import queryString from 'query-string'
import { useLocation } from 'react-router-dom'
import SavePrescreenPopup from "../Popups/SavePrescreenPopup.jsx";
import SaveChangesPopup from "../Popups/SaveChangesPopup";
import SwtAlert from '../../../../Utils/SwtAlert';
import pencilIcon from "../../../../assets/images/pencil-black.svg";
import { ScreenLoader } from "@iv/shared/components/atoms/Loader/index.js";
import cogoToast from "cogo-toast";
const totalSteps = Object.keys(StepperSteps);

const PrescreenForm = ({ 
  interviewPrescreenData, 
  setInterviewPrescreenData, 
  handleSaveChanges, 
  handleSavePrescreen,
  getQuestionByFilters,
  originalQuesList,
  technicalQuesList,
  technicalAllowedTags,
  setTechnicalAllowedTags,
  techPageCount,
  techCurrentPage,
  setTechCurrentPage,
  questionPerPage,
  totalQuestion,
  searchText,
  setSearchText,
  setIsSaveBtnDisable,
  isSaveBtnDisable,
  questionTypeList,
  defaultMarkingList,
  topicList,
  setTopicList,
  showTopicBox,
  setShowTopicBox,
  loader,
  setLoader,
  uploadFileV2,
  getAllSkillsAPI,
  fileUpload,
}) => {

  const [originalQuestionList, setOriginalQuestionList] = useState(originalQuesList)

  const [currentStep, setCurrentStep] = useState(1); //To check on which form step we are, by default it is set as 1 i.e.Guidelines and Intro
  const [isButtonClickDisable, setButtonClickDisable] = useState(false); //To disable the stepper click when save API is already in progress
  const [commonPayload, setCommonPayload] = useState({
    round_name: "",
  });
  const [technicalSelectedQuestion,setTechnicalSelectedQuestion] = useState([]);
  const [technicalQuestionList,setTechnicalQuestionList] = useState([]);
  const [skillListOptions,setSkillListOptions] = useState([]);

  const history = useHistory();
  const { search } = useLocation();
  const parsedQueryString = queryString.parse(search);
  const client_id = parsedQueryString?.client_id;
  const type = parsedQueryString?.type;
  const role_name = interviewPrescreenData?.role || parsedQueryString?.role_name;
  
  const getAllSkills = () => {
    const payload = {
      roles_json: [interviewPrescreenData?.role] || [],
    }
    getAllSkillsAPI(payload).then(res=>{
      const data = res?.data?.data;
      let skillList = data?.map(item=>({label:item?.skill_name,value:item?.skill_name,id:item?.id,data:item})) || [];
      skillList = [{label:"Select skill",value:""},...skillList]
      setSkillListOptions(skillList);
    }).catch(error=>{
        cogoToast?.error(error?.data?.msg?.toString() || error?.data?.data?.toString() || 
        error?.data?.toString() || error?.toString(),{
          position:"top-right"
        })
    });
}


  useEffect(() => {
    if (type === "edit" || type === "duplicate") {
      setCommonPayload({
        role_name: interviewPrescreenData?.role || role_name,
      });    
    }
  }, [interviewPrescreenData]);


  useEffect(() => {
    setOriginalQuestionList(originalQuesList);
    setTechnicalQuestionList(originalQuesList || []);
  }, [originalQuesList]);

  useEffect(()=>{
    if(interviewPrescreenData?.role)
      getAllSkills();
  },[interviewPrescreenData?.role])

  const handleBackClick = () => {
    history.goBack();
  };
  const handlePageClick = (clickedStep) => {
    //To handle saving data on step change. e.g. API calls
    setCurrentStep(clickedStep);
  };

  const handleCompleteSetup = () => {
    //To handle final setup to be completed
  };

  const renderHeader = () => {
    return (
      <Box display="flex" flexDirection="column" className="header">
        <Box
          display="flex"
          alignItems="center"
          onClick={handleBackClick}
          className="back-click"
        >
          <ArrowBack fontSize="small" />
          <Typography className="go-back">Go Back</Typography>
        </Box>
        <Typography className="prescreen-category-role" mt="10px">{type && type==='edit'?<span className='text-transform-capitalize'>Edit</span>:<span className='text-transform-capitalize'>Add</span>}&nbsp;{`(${commonPayload.role_name?.length > 0 ? commonPayload.role_name : parsedQueryString?.role_name?.toUpperCase()}) Prescreening Template`}</Typography>
        <Typography className="template">
        
        {type === "edit" && interviewPrescreenData?.title ? (
              <Typography>{interviewPrescreenData?.title}</Typography>
          ) : (
            "New Template"
          )}
        </Typography>
      </Box>
    );
  };

  const renderStepper = () => {
    return (
      <Stepper
        currentStep={currentStep}
        onStepperClick={handlePageClick}
        stepperClickDisable={isButtonClickDisable}
      />
    );
  };

  const renderForm = () => {

    return (
      <Box display="flex">
        {currentStep === 1 && (
          <TestConfigurations
          preScreenData={interviewPrescreenData} 
          setPreScreenData ={setInterviewPrescreenData}
          />
        )}
        {currentStep === 2 && (
          <QuestionSet
            interviewPrescreenData={interviewPrescreenData}
            setInterviewPrescreenData={setInterviewPrescreenData}
            getQuestionByFilters={getQuestionByFilters}
            originalQuestionList={technicalQuesList}
            technicalQuestionList={technicalQuestionList}
            setTechnicalQuestionList={setTechnicalQuestionList}
            allowedTags={technicalAllowedTags}
            setAllowedTags={setTechnicalAllowedTags}
            selectedQuestion={technicalSelectedQuestion}
            setSelectedQuestion={setTechnicalSelectedQuestion}
            role_name={role_name}
            pageCount={techPageCount}
            totalQuestion={totalQuestion}
            currentPage={techCurrentPage}
            setCurrentPage={setTechCurrentPage}
            questionPerPage={questionPerPage}
            searchText={searchText}
            setSearchText={setSearchText}
            questionTypeList={questionTypeList}
            defaultMarkingList={defaultMarkingList}
            topicList={topicList}
            setTopicList={setTopicList}
            showTopicBox={showTopicBox}
            setShowTopicBox={setShowTopicBox}
            uploadFileV2={uploadFileV2}
            skillListOptions={skillListOptions}
            fileUpload={fileUpload}
          />
        )}
      </Box>
    );
  };

  const onSaveNewPrescreen = (interviewPrescreenData, client_id, role_name, category_name) => {
    interviewPrescreenData["client_id"] = client_id;
    interviewPrescreenData["role_name"] = role_name;
    interviewPrescreenData["subtitle"] = "";

    handleSavePrescreen(interviewPrescreenData, client_id);
  }
  const renderFooter = () => {
    return (
      <Box
        display="flex"
        className="footer mt-20 mb-5"
        justifyContent="space-between"
      >
        <div
          className={`arrow ${currentStep === 1 && "arrow-disabled"} ${isButtonClickDisable ? "not-allowed" : "pointer"
            }`}
          onClick={() =>
            !isButtonClickDisable && currentStep > 1
              ? handlePageClick(currentStep - 1)
              : null
          }
        >
          <ArrowBackOutlined className="mr-10" /> Previous Step
        </div>

        <div
          className={`arrow text-align-right ${isButtonClickDisable ? "not-allowed" : "pointer"
            }`}
          onClick={() =>
            isButtonClickDisable
              ? null
              : currentStep < totalSteps?.length
                ? handlePageClick(currentStep + 1)
                : handleCompleteSetup()
          }
        >
          {currentStep === totalSteps?.length ? (
            parsedQueryString?.type ===
              "edit" ? (
                <SaveChangesPopup handleSaveChanges={handleSaveChanges} client_id={client_id} prescreen_id={parsedQueryString?.prescreen_id ? parsedQueryString?.prescreen_id : ""} interviewPrescreenData={interviewPrescreenData}
                setInterviewPrescreenData={setInterviewPrescreenData} setIsSaveBtnDisable={setIsSaveBtnDisable} isSaveBtnDisable={isSaveBtnDisable} setLoader={setLoader}/>
            ) : (
                <SavePrescreenPopup type={type} interviewPrescreenData={interviewPrescreenData} handleSavePrescreen={onSaveNewPrescreen}
                setInterviewPrescreenData={setInterviewPrescreenData} client_id={client_id} role_name={commonPayload?.role_name?.length > 0 ? commonPayload?.role_name : parsedQueryString?.role_name}
                category_name={commonPayload?.category_name?.length > 0 ? commonPayload?.category_name : parsedQueryString?.category_name} setIsSaveBtnDisable={setIsSaveBtnDisable} isSaveBtnDisable={isSaveBtnDisable}
                setLoader={setLoader} />
            )
          ) : (
            <span>
              Next Step <ArrowForwardOutlined className="ml-10" />
            </span>
          )}
        </div>
      </Box>
    );
  };

  return (
    <>
      <Box className={"interview-prescreen-container"}>
      {loader &&
            <ScreenLoader />
        }
        {renderHeader()}
        {renderStepper()}
        <hr />
        {renderForm()}
      </Box>
      {
        renderFooter()
      }
    </>
  );
};

export default PrescreenForm;
