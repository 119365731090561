import CircularProgress from "@mui/material/CircularProgress";
import Modal from "@mui/material/Modal";
import "./loader.scss";

export const ScreenLoader = () => {
    return (
      <Modal open={true}>
        <CircularProgress size="100px" className="loader-style"/>
      </Modal>
    )
};

export const TableLoader = () => {
    return (
      <div className="loading-indicator">
        <img
          alt="loader"
          className="social-common"
          src="images/loading-spinner.svg"
        />
      </div>
    );
  };