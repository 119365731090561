import { useRef, useMemo } from "react";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import styled from 'styled-components';

const CustomReactQuill = styled(ReactQuill)`{
    img{
      max-height: 400px;
      max-width: 60%;  
      margin: 6px;
      display: block;
    }
    p{
        word-break: break-all;
    }
}`

export const ReactQuillComponent = ({name, value, handleChange, uploadFileV2, isDisabled=false}) => {
    let quillRef = useRef();

    const handleQuillImageUpload = () => {
        const input = document.createElement("input");
        input.setAttribute("type","file");
        input.setAttribute("accept", "image/png, image/jpeg");
        input.click();
        input.onchange = async (e) => {
            const file = e?.target?.files?.[0] || e[0];
            const response = await uploadFileV2(file,{
                public: "True",
            });
            if(response)
            {   
                const uploadedFileURL = response?.data?.["url"] + response?.data?.fields["key"];
                let quill = quillRef?.current?.getEditor();
                const range = quill?.getSelection(true);
                quill?.insertEmbed(range.index, 'image', uploadedFileURL);
            }
        }
    };
        
    const quillModules = useMemo(() => ({ 
        toolbar: {
            container: [
                [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
                [{size: []}],
                ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
                ['link', 'image', 'video'],
                ['clean']
            ],
            handlers: {
                image: handleQuillImageUpload,
            }  
        },
        clipboard: {
          // toggle to add extra line breaks when pasting HTML:
          matchVisual: false,
        }
    }),[])

    const quillFormats = [
        'header', 'font', 'size',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'list', 'bullet', 'indent',
        'link', 'image', 'video'
    ]

    return (
        <>
            <CustomReactQuill 
                theme="snow"
                value={value}
                name={name}
                onChange={(value)=>handleChange(name, value)}
                modules={quillModules}
                formats={quillFormats}
                readOnly={isDisabled}
                ref={quillRef}
            />
        </>
    )
}