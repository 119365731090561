import {useState, useEffect} from "react";
import {Modal} from "../../../atoms/Modal/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ButtonMUI from "@mui/material/Button";
import Select from "react-select";
import styled from "styled-components";
import {styled as styled_mui} from "@mui/material/styles";
import cogoToast from "cogo-toast";
const FormSelect = styled(Select)`
  display: inline-block;
  padding: 0;
  border-radius: 4px;
  margin-top:12px;
  width: 95% !important;
  align-content: center;
  font-family:'Poppins';
  font-size:14px;
  font-weight:400;
  > div {
    margin-left: 0;
    background-color: #FFFFFF;
    border: 1px solid rgba(0, 0, 0, 0.23);
  }
`;

const Button  = styled_mui(ButtonMUI)`
  font-family:Poppins;
  font-size:14px;
  font-weight:600;
  color:white;
  text-transform:capitalize;
  background:#123FBC;
  padding:6px 22px;
  float:right;
  &:hover {
    background:#123FBC;
    color:white;
  }
`;

const SelectTopicPopup = ({setOpenQuestionBank,selectTopicModal,setSelectTopicModal,topicList}) => {
    const [topicOption,setTopicOption] = useState([]);
    const Title = () => {
        return <Box>
                <Typography fontFamily="Poppins" fontSize="16px" fontWeight="600">Select Question Topic</Typography>
        </Box>
    }
    const handleClose = () => {
        selectTopicModal.status = false;
            setSelectTopicModal({...selectTopicModal});
    }
    useEffect(()=>{
        if(topicList && topicList?.length > 0)
        {
            let arr = topicList?.map(topic=>({label:topic?.topicName,value:topic.topicName})) || [];
            arr = [{label:"Select Topic",value:""},...arr];
            setTopicOption(arr);
        }
    },[topicList])
    const Body = () => {
        const handleSelect = (e) => {
            selectTopicModal.selectedTopic = e?.value;
            setSelectTopicModal({...selectTopicModal});
        }
        const handleClick = () => {
            if(!selectTopicModal?.selectedTopic || selectTopicModal?.selectedTopic?.length == 0)
            {
                cogoToast?.error("Please select question topic");
                return;
            }
            handleClose();
            setOpenQuestionBank(true);
        }
        return <Box display="flex" flexDirection="column" gap="6px">
                <Typography fontFamily="Poppins" fontSize="14px" fontWeight="400">Please choose a question topic for which you want to add the questions.</Typography>
                <FormSelect
                options={topicOption}
                placeholder="Select topic"
                onChange={handleSelect}
                value={
                    selectTopicModal?.selectedTopic?
                    {label:selectTopicModal?.selectedTopic,value:selectTopicModal?.selectedTopic} :
                    topicOption[0]
                }
                />
                <Box width="95%" marginTop="22px">
                    <Button onClick={handleClick}>
                    Proceed
                    </Button>
                </Box>
        </Box>
    }
    return <Box>
        <Modal
        width="600px"
        height="400px"
        onClose={handleClose}
        open={selectTopicModal?.status}
        title={<Title/>}
        body={<Body/>}
        />
    </Box>
}
export default SelectTopicPopup;