import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import swal from "sweetalert";

import axios from '../Apis/axios';
import reducers from '../reducers';
import { auth } from "../reducers/Auth"
import { user } from "../reducers/Users"
import { positions } from "../reducers/Positions"
import { clients } from "../reducers/Clients"
import { interviewers } from "../reducers/Interviewer"
import { reschedules } from  "../reducers/Reschedule"

import { persistStore, persistCombineReducers } from 'redux-persist';
import storage from 'redux-persist/es/storage';
import {refreshAuth} from "../Apis/auth";
import SwtAlert from "../Utils/SwtAlert";
import {Redirect } from "react-router-dom";


export default function configureStore() {
	const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

	const config = {
		key: 'root',
		storage,
		debug: true
	};

	const appReducer = persistCombineReducers(config, {
    ...reducers,
    auth,
    user,
    clients,
    positions,
    interviewers,
    reschedules,
  });

	const rootReducer = (state, action) => {
    if (action.type === "persist/REHYDRATE") {
      const payload = action.payload;
      if (payload?.auth?.access_token) {
        axios.defaults.headers.common['Authorization'] = `Bearer ${payload.auth.access_token}`;
      } else {
        axios.defaults.headers.common['Authorization'] = null;
      }
    }

    if (action.type === "AUTH_LOGOUT_SUCCESS") {
      const { _persist } = state;
      state = { _persist };
      axios.defaults.headers.common['Authorization'] = null;
    }

    return appReducer(state, action);
  };

	const store = createStore(
    rootReducer,
		composeEnhancers(
			applyMiddleware(thunk, logger)
		)
	);

  const { dispatch } = store; // direct access to redux store.
  let isRefreshing = false;
  let UNAUTHORIZED_REQUESTS_QUEUE = [];
  const UNAUTHORIZED = 401;
  let logout = false;

	const handleLogout = (type) => {
    axios.defaults.headers.common['Authorization'] = null;
  
    if(type != 'refreshExpired') {
      swal("Unauthorized Access! Please login again", {
        icon: "warning",
        buttons: false,
        timer: 3000,
      });
    }
  
    dispatch({
      type: "SET_AUTH_USER",
      payload: {},
    });
    dispatch({
      type: "AUTH_LOGOUT_SUCCESS",
      payload: null,
    });
    window.location.href = '#/auth/login';
  };

	const handleAuthUpdate = (access_token, refresh_token) => {
    axios.defaults.headers.common['Authorization'] = `Bearer ${access_token}`;
    dispatch({
      type: "AUTH_LOGIN_SUCCESS",
      payload: {
        access_token,
        refresh_token
      },
    });
  };

	const processUnauthorizedQueue = (error, token = null) => {
    UNAUTHORIZED_REQUESTS_QUEUE.forEach((p) => {
      if (error) {
        p.reject(error);
      } else {
        p.resolve(token);
      }
    });

    UNAUTHORIZED_REQUESTS_QUEUE = [];
  };

  axios.interceptors.response.use(
    (response) => response,
    async (error) => {
      const status = error?.response?.status;
      if (status === UNAUTHORIZED && !error?.config?.__isRetryRequest) {
        try {
          if (isRefreshing) {
            try {
              if (error?.config?.url == "/auth/refresh" && status === UNAUTHORIZED ){
                 logout = true;
               }
                if (logout && status === UNAUTHORIZED ){
                SwtAlert("Your login session has expired. Please login again...", 3000, "error", false);
                 // set time out time is set to 4 sec so that we can logout it after swtalert disappears.
                setTimeout(() => {
                  handleLogout('refreshExpired');
                },3000)
              }
              const token = await new Promise((resolve, reject) => {
                UNAUTHORIZED_REQUESTS_QUEUE.push({ resolve, reject });
              });
              error.config.headers.Authorization = `Bearer ${token}`;
              return axios(error.config);
            }
            catch (e) {
              return e;
            }
          }

          const currentState = store.getState();
          const { refresh_token: current_refresh_token } = currentState.auth;

          if (!current_refresh_token) {
            handleLogout();
            return;
          }

          isRefreshing = true;
          error.config.__isRetryRequest = true;
          return new Promise(async (resolve, reject) => {
            try {
              const response = await refreshAuth(current_refresh_token);
              if (response) {
                logout =  false;
              } 
              const {access_token, refresh_token} =  response;
              handleAuthUpdate(access_token, refresh_token);

              isRefreshing = false;
              error.config.headers.Authorization = `Bearer ${access_token}`;
              processUnauthorizedQueue(null, access_token);
              resolve(axios(error.config));
            } catch (e) {
              processUnauthorizedQueue(e, null);
              reject(error.response);
            }
          });
        }
        catch(e) {
          handleLogout();
        }
      }
      return Promise.reject(error);
    }
  );

	const persistor = persistStore(store);

	return { persistor, store };
}