import * as React from "react";
import MuiAccordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled as styled_mui } from "@mui/material/styles";
import durationIcon from "../../../../../assets/images/duration-icon.svg";
import TickIcon from '@mui/icons-material/CheckRounded';
import { OPTIONS } from "../../Constants";
import { OPTIONS_LABEL } from "../../Constants";
import rightTick from "../../../../../assets/images/green-circle-tick.svg";
import "./QuestionList.scss";
const INTEGER = "INT_OUTPUT_BASED";
const CustomCheckBox = styled_mui(Checkbox)({
  "&.Mui-checked": {
    color: "#123FBC",
    boxShadow: "none",
  },
});

const Accordion = styled_mui((props) => <MuiAccordion {...props} />)(
  ({ theme }) => ({
    "&:nth-child(2n+1)": {
      background: "#F8F9FD",
    },
    "& .MuiCollapse-root": {},
  })
);
const AccordionSummary = styled_mui((props) => (
  <MuiAccordionSummary {...props} />
))(({ theme }) => ({}));
export default function QuestionList({
  type,
  questionList,
  selectedQuestion,
  setSelectedQuestion, 
  topic, 
  currentPage, 
  questionPerPage, 
  selectTopicModal
}) {
  const [expanded, setExpanded] = React.useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleCheckbox = (item)=>{

      let obj = selectedQuestion?.find(data=>data?.questionId===item?.id); 
     if(!obj?.questionId)
     {
      
       obj = {
            isChecked : true,
            question : item,
            questionId : item?.id
       }
       selectedQuestion?.push(obj);
      }
      else
      {
        obj.isChecked = !obj?.isChecked;
      }
     if(selectTopicModal.selectedTopic && selectTopicModal.selectedTopic?.length >0)
     {
       obj.question.topic = selectTopicModal.selectedTopic;
      }
     setSelectedQuestion(()=>{
      return [...selectedQuestion];
    });
  }
  
  
  return (
    <div className="question-bank-accordion">
      {questionList && questionList?.length > 0
        ? questionList?.map((item, index) => {
            return (
              <>
            {selectedQuestion?.find(ques=>ques?.questionId===item?.id)?.question?.topic && topic && selectedQuestion?.find(ques=>ques?.questionId===item?.id)?.question?.topic !== topic
             ? <></> 
             : <Accordion
                expanded={expanded === "panel" + (index + 1)}
                onChange={handleChange("panel" + (index + 1))}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography className="col-style zero-col">
                    <CustomCheckBox
                      checked={selectedQuestion?.find(ques=>ques?.questionId===item?.id)?.isChecked?"checked":""}
                      size="small"
                      onChange={(e) => {
                        setExpanded(false);
                        handleCheckbox(item);
                      }}
                      color="primary"
                    />
                  </Typography>
                  &nbsp;&nbsp;
                  <Box>
                  <Typography className="font-style  col-style first-col font-13 font-400 d-flex">
                    {" "}
                    {(currentPage * questionPerPage) + index + 1}.&nbsp;&nbsp;
                    <Box className="quill-box-table" dangerouslySetInnerHTML={{__html: item?.question_text || ""}} ></Box>
                  </Typography>
                    <Box display="flex" alignItems="center" gap="8px" flexWrap="wrap">
                      <Typography className={`question-difficulty ${item?.difficulty_level === "EASY"?'fg-easy bg-easy':item?.difficulty_level === "MEDIUM"?"fg-medium bg-medium":"fg-hard bg-hard"}`}>{item?.difficulty_level}</Typography>
                     {
                      item?.type && item?.type?.length > 0 ? 
                      <Box>
                        <span className="dot">&#8226;</span>&nbsp;
                      <Typography display="inline-block" className="skill-badge">
                        {item?.type}
                        </Typography>
                      </Box>  
                      : null
                     }

                    {
                      item?.skill?.skill_name && item?.skill?.skill_name?.length > 0 ? 
                      <Box>
                        <span className="dot">&#8226;</span>&nbsp;
                      <Typography display="inline-block" className="skill-badge">
                       {item?.skill?.skill_name}
                        </Typography>
                      </Box>  
                      : null
                     }

                    </Box>
                  </Box>
                  <Box className="column-box">
                  <Typography className="role second-col">
                     {item?.role?.length>0 ? item?.role : ""} 
                    &nbsp;
                  </Typography>
                  <Typography className="font-11 font-400 col-style fourth-col font-style ">
                  {item?.tags?.length>0?item?.tags[0]?.length>12?  '('+ item?.tags[0]?.slice(0,12)+"...)":  '('+ item?.tags[0] +")":""}
                  </Typography>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                      <Box className='option-box'>
                        <Typography>
                          Ans.
                        </Typography>
                        {item?.type === INTEGER ?
                          <Box className="ml-20">
                            {item?.answer_json[0]}
                          </Box>
                          :
                          <ol type="a" className='options-text'>
                            {Object.entries(item?.options_json)?.map(([option, value]) => {
                              return (
                                <li><pre>{value}{item?.answer_json?.includes(OPTIONS_LABEL[option]) && <img src={rightTick} className="right-tick" />}</pre></li>
                              )
                            })}
                          </ol>
                        }
                      </Box>
                  <Typography className="collapse-content font-style font-13 font-400">
                    {item?.question?.name && item?.question?.name?.length > 0
                      ? item?.question?.statement
                      : item?.solution?.solution1?.approach?item?.solution?.solution1?.approach:""}
                  </Typography>
                </AccordionDetails>
              </Accordion> 
             }
              </>
            );
          })
        : ""}
    </div>
  );
}
