import PrescreeningListShared from "@iv/shared/components/pages/PrescreeningListForm/PrescreenList";

import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import SwtAlert from "../../../../Utils/SwtAlert";

import { getAllPrescreening, editPreScreeningStatus } from "../../../../Apis/Pre_screening_api";
import { getAllRolesRoundCategoriesDict } from "../../../../Apis/positions_api";
import { connect } from "react-redux";
import cogoToast from "cogo-toast";
import { PRESCREENING_STATUS } from "../../Constant";

const PrescreeningList = (props) => {
  const [roleResponse, setRoleResponse] = useState([]);
  const [prescreeningList, setPrescreeningList] = useState([]);
  const [tabValue, setTabValue] = useState(0);
  const [prescreenActivePositionLinked, setPrescreenActivePositionLinked] = useState([]);

  const history = useHistory();

  // Function to get single url query param
  const getUrlQueryParam = (paramName) => {
    const urlSearchParams = new URLSearchParams(history?.location?.search);
    return urlSearchParams?.get(paramName);
  }

  // Function to set single url query param
  const setUrlQueryParam = (paramName, value) => {
    const urlSearchParams = new URLSearchParams(history?.location?.search);
    urlSearchParams?.set(paramName, value?.toString());
    history?.replace(`?${urlSearchParams?.toString()}`);
  }

  const quickScreenClientId = getUrlQueryParam?.call(this, "client_id") || null;
  const quickScreenClientName = getUrlQueryParam?.call(this, "client_name") || null;
  const quickScreenRole = getUrlQueryParam?.call(this, "role") || null;

  const [selectedClient, setSelectedClient] = useState({
    id:quickScreenClientId || null,
    name:quickScreenClientName || null,
    role:quickScreenRole || null,
  });


  let { clients } = props;
  let clientsList = [];

  for (let i = 0; i < clients?.length; i++) {
    const data = clients[i];
    data.label = clients[i]?.client_name;
    data.value = clients[i]?.id;
    clientsList.push(data);
  }
  clients = clientsList;

  const getRole = async () => {
    getAllRolesRoundCategoriesDict()
      .then((res) => {
        if (res) {
          setRoleResponse(res);
        } else {
          SwtAlert(res, 2000, "error", false);
        }
      })
      .catch((error) => {
        SwtAlert(error?.data, 2000, "error", false);
      });
  };

  const getDefaultTab = () => {
    if(tabValue == 1)
    return PRESCREENING_STATUS.INACTIVE;
   else
    return PRESCREENING_STATUS.APPROVED;
  }

  const getPrescreeningList = (tempRole, tempCategory,  clientId=selectedClient?.id, status=getDefaultTab()) => {
    if(!clientId)
    return;

    const payload = {};
    if (tempRole && tempRole?.length > 0) {
      payload["role"] = tempRole;
    }
    if(clientId){
      payload['client_id'] = clientId || quickScreenClientId;
    }
    payload['status'] = status || getDefaultTab();

    getAllPrescreening(payload)
      .then((res) => {
        if (res?.status === 200) {
          setPrescreeningList(res?.data);
        } else {
          SwtAlert(res?.data, 2000, "error", false);
        }
      })
      .catch((error) => {
        SwtAlert(error?.response, 2000, "error", false);
      });
  };

  const changeQuickScreenStatus = (quickScreenId, quickScreenStatus) => {
    if(quickScreenId) {
      let payload = {}
      payload.pre_screening_id = quickScreenId;
      payload.status = quickScreenStatus;
      editPreScreeningStatus(payload)
      .then((res) => {
        if (res?.status === 200) {
          cogoToast.success(`QuickScreen ${quickScreenStatus?.toLowerCase()} successfully`);
          getPrescreeningList(quickScreenRole, '' , quickScreenClientId);
        } else if (res?.status === 400) {
          setPrescreenActivePositionLinked(res?.data);
        } else {
          SwtAlert(res?.data, 1500, "error", false);
        }
      })
      .catch((err) => {
        SwtAlert(err?.data?.msg || err?.data || err?.msg, 1500, "error", false);
      });
    }
  }
  
  const handleClientSelect=(client) => {
    setUrlQueryParam.call(this, "role", "")
    setSelectedClient((prev)=>({...prev,id:client?.id,name:client?.client_name,role:null}));
  }

  useEffect(()=>{
    if(selectedClient && selectedClient?.id && selectedClient?.name)
    {
      getPrescreeningList(selectedClient?.role, '', selectedClient?.id || quickScreenClientId)
      setUrlQueryParam.call(this, "client_id", selectedClient?.id || quickScreenClientId || '');
      setUrlQueryParam.call(this, "client_name", selectedClient?.name || quickScreenClientName || '')
    }
  }, [selectedClient]);

  useEffect(()=>{
    getRole();
  },[]);

  return (
      prescreeningList && Object.keys(roleResponse)?.length > 0 ? (
        <PrescreeningListShared
          client_id={selectedClient?.id || quickScreenClientId || ""}
          prescreenList={prescreeningList}
          filterList={getPrescreeningList}
          tabValue={tabValue}
          setTabValue={setTabValue}
          roleResponse={roleResponse}
          canAddPrescreen={false}
          canDuplicatePrescreen={true}
          canSelectClient={true}
          clientList = {clients}
          onSelectClient={handleClientSelect}
          changeQuickScreenStatus={changeQuickScreenStatus}
          quickScreenRole={quickScreenRole}
          quickScreenClientId={quickScreenClientId}
          quickScreenClientName={quickScreenClientName}
          prescreenActivePositionLinked={prescreenActivePositionLinked}
        />
      ) : (
        ""
      )
  );
};

const mapStateToProps = (state) => ({
    clients: state.clients,
  });

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(PrescreeningList);
