import React, { Component, Fragment } from "react";
import { withRouter, NavLink } from "react-router-dom";

import MetisMenu from "react-metismenu";

import { Interviews, Client, Interviewer, History, Admin, Guide, Tags, QuestionBank } from "./NavItems";
import { connect } from "react-redux";
import { isAdmin, isSuperAdmin } from "../../Utils";
import "./AppNavBar.scss"

class Nav extends Component {
  state = {};

  renderNavBar = (obj) => {
    return (
      <NavLink activeClassName="active" className="navContainerLink" to={obj.to} key={obj.label}
        onClick={()=>localStorage.removeItem("currentQuery")}>
        <img className="iconSize" src={obj.icon} />
        <p className="app-sidebar__heading textHeading">{obj.label}</p>
      </NavLink>
    )
  }
  render() {
    return (
      <Fragment>
        {isAdmin(this.props.user) && (
          <div className="AppNavBar">
            <h5 className="app-sidebar__heading">Interviews</h5>
            {Interviews.map((obj) => {
              return this.renderNavBar(obj)
            })}

            <h5 className="app-sidebar__heading">Client</h5>
            {Client.map((obj) => {
              return this.renderNavBar(obj)
            })}

            <h5 className="app-sidebar__heading">History</h5>
            {History.map((obj) => {
              return this.renderNavBar(obj)
            })}

            <h5 className="app-sidebar__heading">Templates and Questions</h5>
            {Guide.map((obj) => {
              return this.renderNavBar(obj)
            })}

            {Tags.map((obj) => {
              return this.renderNavBar(obj)
            })}

            {QuestionBank.map((obj) => {
              return this.renderNavBar(obj)
            })}
            
            <h5 className="app-sidebar__heading">Interviewer</h5>
            {Interviewer.map((obj) => {
              return this.renderNavBar(obj)
            })}

            {
              isSuperAdmin(this.props.user) && (
                <>
                  <h5 className="app-sidebar__heading">Admin</h5>
                  {Admin.map((obj) => {
                    return this.renderNavBar(obj)
                  })}
                </>
              )
            }
          </div>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
});

const mapDispatchToProps = (dispatch) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Nav));
