import React, { Suspense, lazy, Fragment } from "react";
import { connect } from "react-redux";
import {Route, Redirect} from 'react-router-dom';
import AppHeader from "../AppHeader";
import AppSidebar from "../AppSidebar/";

import { setEnableMobileMenu } from "../../reducers/ThemeOptions";
import { setPositions } from "../../reducers/Positions";
import { isAdmin, isSuperAdmin } from "../../Utils";
import Prescreening from "../../DemoPages/Prescreening";

const Interviews = lazy(() => import("../../DemoPages/Admin"));
const Client = lazy(() => import("../../DemoPages/Client"));
const Position = lazy(() => import("../../DemoPages/Position"));
const Interviewer = lazy(() => import("../../DemoPages/Interviewer"));
const Authentication = lazy(() => import("../../DemoPages/Authentication"));
const UpdatePassword = lazy(() => import("../../DemoPages/UpdatePassword"));
const History = lazy(() => import("../../DemoPages/History"));
const Admin = lazy(() => import("../../DemoPages/SuperAdmin"));
const Feedback = lazy(() => import("../../DemoPages/Feedback/"));
const InterviewGuides = lazy(() => import("../../DemoPages/InterviewGuide/"));
const GuideTags = lazy(() => import("../../DemoPages/GuideTags"));
const QuestionBank = lazy(() => import("../../DemoPages/QuestionBank/"));
const PrescreeningQuestionBank = lazy(() => import("../../DemoPages/PrescreeningQuestionBank"));
const ResetPassword=lazy(()=>import("../../DemoPages/ResetPassword/ResetPassword"));
const PreScreeningReport = lazy(()=>import("../../DemoPages/Admin/Elements/PrescreeningReport"));
const GoogleLogin =  lazy(()=>import("../../DemoPages/GoogleLogin/GoogleLogin"));
const ApplicationSystem = lazy(() => import("../../DemoPages/Admin/Elements/ApplicationSystem"));
const Skills = lazy(()=>import ("../../DemoPages/Skills/index"));

function RenderComponent({ path, component, redirect }) {
  let queryParam = window.location.href;
  if(queryParam?.indexOf("?") !== -1) {
    queryParam = queryParam?.split('?')[1];
    queryParam = "?"+queryParam;
  }
  else {
    queryParam = "";
  }

  return (
    <Suspense
      fallback={
        <div className="loader-container">
          <div className="loader-container-inner">
            <h6 className="mt-5">
              Please wait while we load all the Components examples
              <small>
                Because this is a demonstration we load at once all the
                Components examples. This wouldn't happen in a real live app!
              </small>
            </h6>
          </div>
        </div>
      }
    >
     {redirect && redirect?.length>0 ? 
      <Route path={path} render={() => <Redirect from={path} to={redirect+queryParam}/> } /> :
      <Route path={path} component={component} />
     }
    </Suspense>
  );
}

function RenderAllComponent({ user, clients }) {
  if (user) {
    return (
      <div>
        <AppHeader clients={clients} />
        <div className="app-main">
          <AppSidebar />

          <RenderComponent path="/reset" component={ResetPassword} />
          {/* Interviews */}
          <RenderComponent path="/interviews" component={Interviews} />
          <RenderComponent path="/interview-feedback" component={Feedback} />

          {/* Client */}
          <RenderComponent path="/client" component={Client} />
          <RenderComponent path="/interviewer" component={Interviewer} />
          <RenderComponent path="/user" component={UpdatePassword} />
          <RenderComponent path="/position" component={Position} />

          {/* History */}
          <RenderComponent path="/history" component={History}/>

          {/* Admin */}
          {isSuperAdmin(user) && 
           <RenderComponent path="/admin" component={Admin} />}

          {/* Guide */}
          <RenderComponent path="/interview-guides" component={InterviewGuides}  />

          <RenderComponent path="/guide-tags" component={GuideTags} />

           {/* Question Bank */}
           <RenderComponent path="/question-bank" component={QuestionBank}  />

            {/* Skills */}
          <RenderComponent path="/skills" component={Skills}  />
        
           <RenderComponent exact path="/prescreen/question-bank" redirect="/quickscreen-question-bank" />
           <RenderComponent exact path="/quickscreen-question-bank" component={PrescreeningQuestionBank} />

           <RenderComponent path="/prescreening" redirect="/quickscreen" />
           <RenderComponent path="/quickscreen" component={Prescreening} />

           <RenderComponent 
              path={`/prescreen/report/:client_id?/:position_id?/:candidate_id?/:prescreen_id?/:prescreen_round_id?`} 
              redirect="/quickscreen-report" 
            /> 
           <RenderComponent 
              path={`/quickscreen-report`} 
              component={PreScreeningReport}  
            /> 

           <RenderComponent path="/application" component={ApplicationSystem}  />
        
        </div>
      </div>
    );
  }

  return (
    <div>
      {/* Auth */}
      <RenderComponent path="/auth" component={Authentication} />
      <RenderComponent path="/reset" component={ResetPassword} />
      <RenderComponent path="/google/user/:token?" component={GoogleLogin}  />
    </div>
  );
}

const AppMain = (props) => {
  const { user, clients } = props;

  return (
    <Fragment>
      <RenderAllComponent user={user} clients={clients} />

      {isAdmin(user) && (
        <Route
          exact
          path="/"
          render={() => <Redirect to="/interviews/all" />}
        />
      )}

      {(!isAdmin(user) || !user) && (
        <>
        <Route exact path="/" render={() => <Redirect to="/auth/login" />} />
        <Route path="/auth" render={() => <Redirect to="/auth/login" />} />
        </>
      )}
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  clients: state.clients,
});

const mapDispatchToProps = (dispatch) => ({
  setEnableMobileMenu: (enable) => dispatch(setEnableMobileMenu(enable)),
  setPositions: (positions) => dispatch(setPositions(positions)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppMain);
