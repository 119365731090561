import * as React from 'react';
import PropTypes from 'prop-types';
import { styled as styled_mui } from '@mui/material/styles';
import styled from "styled-components";
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import cogoToast from "cogo-toast";
import CommonButton from "../../../atoms/Button/CommonButton";
import {popUpStyles} from "../Constants";

import ArrowForwardOutlined from "@material-ui/icons/ArrowForwardOutlined";
const BootstrapDialog = styled_mui(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function SavePrescreenPopup({ interviewPrescreenData, type, setInterviewPrescreenData, client_id, role_name, category_name, handleSavePrescreen, setIsSaveBtnDisable ,isSaveBtnDisable, setLoader  }) {
  const isValidQuestionCount = interviewPrescreenData?.number_of_questions >= interviewPrescreenData?.time_allotted * 2;
  const [open, setOpen] = React.useState(false);
  const handleSave = () => {
    const isQuesTopicNull = interviewPrescreenData?.questions?.filter(
      (item) => !item?.topic
    );
    if(interviewPrescreenData?.questions?.length === 0 ){
      cogoToast.error('Please add QuickScreen test questions')
      return;
    }
     if(interviewPrescreenData?.questions?.length < (interviewPrescreenData?.number_of_questions || 0)){
      cogoToast.error(`Please add atleast ${interviewPrescreenData?.number_of_questions || 0} QuickScreen test questions`)
      return;
    }
    if(isQuesTopicNull?.length > 0){
      cogoToast.error('Please add Quickscreen question topic')
      return;
    }
    if(interviewPrescreenData?.title === '' ){
      cogoToast.error('Please add QuickScreen test name')
      return;
    }
    if(interviewPrescreenData?.number_of_questions === 0 ){
      cogoToast.error('Please add QuickScreen test total number of questions')
      return;
    }
    if(interviewPrescreenData?.cut_off_percentage === 0 ){
      cogoToast.error('Please add QuickScreen test cutoff percentage')
      return;
    }
    if(interviewPrescreenData?.tab_switch_check?.check && interviewPrescreenData?.tab_switch_check?.count === 0 ){
      cogoToast.error('Please add QuickScreen test tab switch count')
      return;
    }

    setIsSaveBtnDisable(true);

    handleSavePrescreen(interviewPrescreenData, client_id, role_name, category_name);
    setOpen(false);
    setLoader(true);
  }

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <CustomContainer>
      <span variant="outlined" onClick={handleClickOpen}>
        Save Template <ArrowForwardOutlined className="ml-10" />
      </span>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle
          className={isValidQuestionCount ?  "font-18 bold-600 font-style  fg-black" : "lessQstnHeading" }
          id="customized-dialog-title"
          onClose={handleClose}
          sx={popUpStyles.headingStyle}
        >
           {isValidQuestionCount ? 'Save template' : 'Are you sure you want to go ahead?'}
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <CustomBox>
          {isValidQuestionCount ? 
            <Box>
              <Typography className="font-14 bold-400 fg-black font-style" gutterBottom>
                   Are you sure you want to save this test template?
               </Typography>
            </Box>
            : 
            <Box sx={popUpStyles.contentBox}>
              <Typography sx={popUpStyles.contentStyles}>
                     You have asked less questions than recommended, are you sure you want to go ahead?
               </Typography>
               <Typography sx={popUpStyles.subTextStyles}>(Recommended: Number of Questions = 2x Number of minutes)</Typography>
            </Box>
            }
            <Typography gutterBottom>
               {isValidQuestionCount ? 
                    <CommonButton 
                      buttonName={'SAVE'}
                      onClick={handleSave}
                      isDisable={isSaveBtnDisable}
                      styleClassName={'proceed_button_save_prescreen'}
                   />
               :
               <Box sx={popUpStyles.buttonBox}>
                     <CommonButton 
                       buttonName={'Cancel'}
                       onClick={handleClose}
                       styleObject={popUpStyles.cancelBtn}
                   />
                    <CommonButton 
                       buttonName={'Confirm'}
                       onClick={handleSave}
                       styleObject={popUpStyles.confirmBtnBtn}
                   />
               </Box>
            }
            </Typography>
          </CustomBox>
        </DialogContent>
      </BootstrapDialog>
    </CustomContainer>
  );
}

const CustomContainer = styled.div`
    display: inline-block;
    font-family: 'Poppins';
    letter-spacing: 0.005em;
    cursor: pointer;
`;

const CustomBox = styled.div`
    .proceed_button_save_prescreen {
    width: 180px;
    height: 40px;
    font-size: 14px;
    font-family: 'Poppins';
    font-weight: 600;
    letter-spacing: 0.005em;
    color: white;
    background-color: #123FBC;
    float: right;
    margin: 20px auto 10px auto;
    border: 1px solid  #123FBC;
    border-radius: 4px;
}

.proceed_button_save_prescreen:hover{
  background-color: #123FBC;
  color: white;
}

.customInput {
    height: 46px;
    width: 450px;
    border: none;
    outline: none;
    border: 1px solid #C9C9C9;
    border-radius: 4px;
    padding-left: 8px;
    font-size: 14px;
    font-weight: 600;
    font-family: 'Poppins';
    letter-spacing: 0.005em;
    color: #3D3D3D;
    margin-top: 5px;
}

.customInput::placeholder {
    color: #A5A5A5;
}

.customError {
    font-size: 12px;
    font-weight: 400;
    font-family: 'Poppins';
    letter-spacing: 0.005em;
    color: #DC4F4F;
    margin-top: 5px;
}

.width-380 {
    width: 380px;
}

.font-style {
    font-family: 'Poppins';
    letter-spacing: 0.005em;
}

.fg-black {
    color: #3D3D3D;
}

.fg-gray {
    color: #ABABAB;
}

.bold-600 {
    font-weight: 600;
}

.bold-500 {
    font-weight: 500;
}

.bold-400 {
    font-weight: 400;
}

.font-18 {
    font-size: 18px;
}

.font-14 {
    font-size: 14px;
}

.font-12 {
    font-size: 12px;
}

`;