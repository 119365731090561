import PrescreeningFormShared from "@iv/shared/components/pages/PrescreeningListForm/PrescreenForms";

import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import queryString from "query-string";

import SwtAlert from "../../../../Utils/SwtAlert";
import {
  getPrescreeningByPrescreeningId,
  editPreScreening,
  createPreScreening,
  getFilterOptionsAPI,
  getQuestionBankListAPI,
  getPrescreeningDefaultMarking,
  getAllSkillsAPI,
} from "../../../../Apis/Pre_screening_api";;
import { uploadFileV2 } from "../../../../Apis/file_api";

const PrescreeningForm = () => {
  const questionPerPage = 50;
  const [totalQuestion, setTotalQuestion] = useState(0);

  const history = useHistory();
  const { search } = useLocation();
  const parsedQueryString = queryString.parse(search);
  const client_id = parsedQueryString?.client_id;
  const type = parsedQueryString?.type;
  const [role_name, setRoleName] = useState(parsedQueryString?.role_name || "");
  const [prescreen_id, setPrescreenId] = useState(parsedQueryString?.id || "");

  const [originalQuestionList, setOriginalQuestionList] = useState([]);
  const [technicalQuestionList, setTechnicalQuestionList] = useState([]);
  const [technicalAllowedTags, setTechnicalAllowedTags] = useState([]);
  const [questionTypeList, setQuestionTypeList] = useState([]);
  const [defaultMarkingList, setDefaultMarkingList] = useState([]);
  const [techPageCount, setTechPageCount] = useState(1);
  const [techCurrentPage, setTechCurrentPage] = useState(0);
  const [searchText, setSearchText] = useState('');
  const [isSaveBtnDisable, setIsSaveBtnDisable] = useState(false);
  const [topicList,setTopicList] = useState([]);
  const [showTopicBox,setShowTopicBox] = useState(false);

  const [loader, setLoader] = useState(false);
  const defaultValue = {
    "title": "",
    "role": parsedQueryString?.role_name || "",
    "number_of_questions": 0,
    "cut_off_percentage": 0,
    "time_allotted": 10,
    "test_validity": 2,
    start_timestamp: '',
    time_window: '',
    last_allowed_submission_timestamp: '',
    "questions": [],
    negative_marking: false,
    tab_switch_check: {check: false, count: ""},
  }
  const [interviewPrescreenData, setInterviewPrescreenData] = useState({...defaultValue});
  
  const getEditPrescreen = () => {
    let error = "";

    if (!client_id) {
      error = "Client id is not present";
    } else if (!prescreen_id) {
      error = "QuickScreen id is not present";
    }

    if (error) {
      SwtAlert(error, 2000, "error", false);
      setTimeout(() => {
        history.goBack();
      }, 2000);
      return;
    }

    getPrescreeningByPrescreeningId({},prescreen_id)
      .then((res) => {
        if (res?.status === 200) {
          setInterviewPrescreenData(res?.data);
          setRoleName(res?.data?.role || '');
          if(res?.data?.section_wise_questions)
          {
              let arr = [];
              Object.entries(res?.data?.section_wise_questions).map(([topic,count])=>{
                  arr.push({
                      topicName:topic,
                      topicCount:count,
                  })
              });
              if(arr && arr.length > 0)
              {
                  setShowTopicBox(true);
              }
              setTopicList(arr || []);
          }
        } else {
          SwtAlert(res?.data, 2000, "error", false);
        }
      })
      .catch((error) => {
        SwtAlert(error?.data, 2000, "error", false);
      });
  };

  const handleSaveChanges = (client_id, prescreen_id, interviewPrescreenData) => {
    if(interviewPrescreenData?.test_validity){
      delete interviewPrescreenData['start_timestamp'];
      delete interviewPrescreenData['time_window'];
      delete interviewPrescreenData['last_allowed_submission_timestamp'];
    }else{
      delete interviewPrescreenData['test_validity'];
      interviewPrescreenData['time_window'] =  parseInt(interviewPrescreenData?.time_window || 0);
    }

    if(topicList && topicList?.length > 0)
    {
      let obj = {}
      topicList?.forEach(topic=>{
        if(topic?.topicName)
        {
          obj[`${topic?.topicName}`] = +topic?.topicCount || 0;
        }
      })

      interviewPrescreenData['section_wise_questions'] = obj
    }
    interviewPrescreenData['pre_screening_id'] = interviewPrescreenData.id
    let payload = interviewPrescreenData;
    let questionData = payload.questions?.map(ques=>{
      ques.skill = ques.skill?.id || ques.skill;
      return ques;
    }) 
    payload.questions = questionData;
    editPreScreening(payload)
      .then((res) => {
        if (res?.status === 200) {
          setIsSaveBtnDisable(false);
          setLoader(false);
          history.push("/quickscreen/list");
        } else {
          setLoader(false);
          SwtAlert(res?.data, 1500, "error", false);
          setIsSaveBtnDisable(false);
        }
      })
      .catch((error) => {
        setLoader(false);
        SwtAlert(error.response, 1500, "error", false);
        setIsSaveBtnDisable(false);
      });
  };

  const handleSavePrescreen = (
    interviewPrescreenData,
    client_id
  ) => {

    if(interviewPrescreenData?.test_validity){
      delete interviewPrescreenData['start_timestamp'];
      delete interviewPrescreenData['time_window'];
      delete interviewPrescreenData['last_allowed_submission_timestamp'];
    }else{
      delete interviewPrescreenData['test_validity'];
      interviewPrescreenData['time_window'] =  parseInt(interviewPrescreenData?.time_window || 0);
    }

    if(topicList && topicList?.length > 0)
    {
      let obj = {}
      topicList?.forEach(topic=>{
        if(topic?.topicName)
        {
          obj[`${topic?.topicName}`] = +topic?.topicCount || 0;
        }
      })

      interviewPrescreenData['section_wise_questions'] = obj
    }

    interviewPrescreenData['client_id'] = client_id;
    let payload = interviewPrescreenData;
    let questionData = payload.questions?.map(ques=>{
      ques.skill = ques.skill?.id || ques.skill;
      return ques;
    }) 
    payload.questions = questionData;
    createPreScreening(payload)
      .then((res) => {
        if (res?.status === 200) {
          setLoader(false);
          SwtAlert("Template created successfully", 1500, "success", false);
          setIsSaveBtnDisable(false);
          history.push("/quickscreen/list");
        } else {
          setLoader(false);
          SwtAlert(res?.data, 1500, "error", false);
          setIsSaveBtnDisable(false);
        }
      })
      .catch((error) => {
        setLoader(false);
        SwtAlert(error.response, 1500, "error", false);
        setIsSaveBtnDisable(false);
      });
  };

  const getAllowedtags = () => {
    getFilterOptionsAPI().then((res) => {
      if (res.status === 200) {
        const tempArr = [
          { label: "Select Tags", value: "", name: "Select all" },
        ];

        res?.data?.tags &&
          res?.data?.tags?.forEach((item) => {
            tempArr.push({ label: item, value: item, name: item });
          });
        setTechnicalAllowedTags(tempArr);

        const typeArr = [{ label: "All", value: "", name: "type" }];
        res?.data?.type && res?.data?.type?.length > 0 &&
        res?.data?.type?.forEach((type) => {
          typeArr?.push({ label: type, value: type, name: "type" });
        });
        setQuestionTypeList(typeArr);
      } else {
        SwtAlert(res?.data, 2000, "error", false);
      }
    })
      .catch((error) => {
        SwtAlert(error, 2000, "error", false);
      });
  }

  const getQuestionByFilters = (payload = {}, type) => {
    return getQuestionList(payload, type)
  }

  const getQuestionList = (payload = {}, type) => {
    payload["page_size"] = questionPerPage;
    payload["question_status"] = ["APPROVED"];
    let filterData = JSON.parse(localStorage.getItem("filterApplied"));
    if(filterData?.length > 0) payload["difficulty_level"] = filterData;
    let filterResponse = null;
    if (!payload.page_number) payload["page_number"] = techCurrentPage + 1;

    filterResponse = getQuestionBankListAPI(payload).then(res => {
      if (res?.status === 200) {
        setOriginalQuestionList(res?.data?.data);
        setTechnicalQuestionList(res?.data?.data);
        setTechPageCount(Math.ceil(res?.data?.metadata?.total / questionPerPage));
        setTotalQuestion(res?.data?.metadata?.total);
      }
      return res?.data;
    }).catch(error => {
      SwtAlert(error?.data, 2000, "error", false);
      return error?.data;
    })
    return filterResponse;
  }

  const getDefaultMarksData = () => {
    getPrescreeningDefaultMarking().then((res) => {
      if (res.status === 200) {
        setDefaultMarkingList(res?.data);
      } else {
        cogoToast.error(res?.data || "Unable to get default marking", 2000, "error", false);
      }
    })
      .catch((error) => {
        cogoToast.error(error?.data || error || "Unable to get default marking", 2000, "error", false);
      });
  }

  useEffect(() => {
    // if (role_name && role_name?.length !== 0)
      getQuestionList({ role: [role_name] }, ['technical', 'theoretical']);
  }, [role_name]);

  useEffect(() => {
    if (role_name && role_name?.length !== 0) {
      let text = (searchText?.technical  && searchText?.technical?.length > 0) ? searchText?.technical : (searchText?.theoretical && searchText?.theoretical?.length > 0) ? searchText?.theoretical : searchText
      let payload = {
        query_text: text
      };
      getQuestionList(payload, 'technical');
    }
  }, [techCurrentPage]);

  useEffect(() => {
console.log(type)

    if (type === "edit" || type === "duplicate") {
      getEditPrescreen();
    }else{
      setInterviewPrescreenData({...defaultValue});
    }
    getAllowedtags();
    getDefaultMarksData();
    localStorage.removeItem("filterApplied");
  }, []);

  return (
      ( type === 'new' || ((type === "edit" || type === "duplicate") && interviewPrescreenData)) &&
        <PrescreeningFormShared
          interviewPrescreenData={interviewPrescreenData}
          setInterviewPrescreenData={setInterviewPrescreenData}
          handleSaveChanges={handleSaveChanges}
          handleSavePrescreen={handleSavePrescreen}
          getQuestionByFilters={getQuestionByFilters}
          originalQuesList={originalQuestionList}
          technicalAllowedTags={technicalAllowedTags}
          setTechnicalAllowedTags={setTechnicalAllowedTags}
          technicalQuesList={technicalQuestionList}
          techPageCount={techPageCount}
          techCurrentPage={techCurrentPage}
          setTechCurrentPage={setTechCurrentPage}
          questionPerPage={questionPerPage}
          totalQuestion={totalQuestion}
          searchText={searchText}
          setSearchText={setSearchText}
          setIsSaveBtnDisable={setIsSaveBtnDisable}
          isSaveBtnDisable={isSaveBtnDisable}
          questionTypeList={questionTypeList}
          defaultMarkingList={defaultMarkingList}
          topicList={topicList}
          setTopicList={setTopicList}
          showTopicBox={showTopicBox}
          setShowTopicBox={setShowTopicBox}
          loader={loader}
          setLoader={setLoader}
          uploadFileV2={uploadFileV2}
          getAllSkillsAPI={getAllSkillsAPI}
          fileUpload={uploadFileV2}
        />
  );
};

export default PrescreeningForm;