import axios from './axios'

import env from "../config/env";

export const uploadFileV2 = async (file, payload) => {
  try {
    const response = await axios({
      url: `/utils/file/upload/${file.name}`, //your url
      method: "get",
      params: payload,
    });

    var formData = new FormData();

    Object.keys(response.data.fields).forEach((key) => {
      formData.append(key, response.data.fields[key]);
    });

    formData.append("file", file);

    const upload = await axios({
      url: response.data.url,
      method: "post",
      data: formData,
      headers: null
    });

    var XMLParser = require("react-xml-parser");
    var xml = new XMLParser().parseFromString(upload.data); // Assume xmlText contains the example XML
    if (upload.status === 201) {
      return response;
    } else {
      alert("Cannot upload the file due to some server issue");
    }
    // return upload.data;
  } catch (err) {
    alert("Cannot upload the file due to some server issue");
  }
};

export const downloadFile = async (url) => {
  const response = await axios({
    url: `/utils/file/download?${url}`, //your url
    method: "GET"
  });
  window.open(response.data, "_blank");
};

export const downloadVideo = (url, payload) => {
  return axios.post(`/utils/file/download?${url}`, payload)
  .then((response) => {
    return response;
  })
  .catch((err) => {
    return err.response;
  });
};

export const refreshAudit = (auditId) => {
  return axios.get(`/interview_audit/${auditId}/interview_details/refresh`)
  .then((response) => {
    return response;
  })
  .catch((err) => {
    return err.response;
  })
}

export const unArchiveVideo = (url) => {
  return axios.get(`/utils/file/retrieve?${url}`)
  .then((response) => {
    return response;
  })
  .catch((err) => {
    return err?.response;
  })
}


export const downloadFile2 = (url) => {
  url = `${env.api.baseUrl}/download_file?$?${url}`;
  var a = document.createElement("a");
  var xhr = new XMLHttpRequest();
  xhr.open('GET', url, true);
  xhr. withCredentials = true;
  xhr.responseType = 'arraybuffer';
  xhr.onload = function () {
    if (this.status===200) {

      // get file name
      var filename = "";
      var disposition = xhr.getResponseHeader('Content-Disposition');
      if (disposition && disposition.indexOf('attachment')!== -1) {
        var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        var matches = filenameRegex.exec(disposition);
        if (matches!=null && matches[1]) filename = matches[1].replace(/['"]/g, '');
      }
      var type = xhr.getResponseHeader('Content-Type');

      // create downloadable file from the blob
      var blob = new Blob([this.response], {type: type});
      var URL = window.URL || window.webkitURL;
      var downloadUrl = URL.createObjectURL(blob);

      // create dummy <a> element and make download
      if (filename) {
        var a = document.createElement("a");
        a.href = downloadUrl;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
      } else {
        window.open(downloadUrl, '_blank');
        // window.location = downloadUrl;
      }
      setTimeout(function () {
        URL.revokeObjectURL(downloadUrl);
      }, 100); // cleanup
    } else {
      console.log('unable to download')
    }
  };
  xhr.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
  xhr.send(url);
};

export const imageFile = (url) => {
  return axios({
    url: `/download_file?${url}`, //your url
    method: "GET",
    responseType: "blob", // important
  });
};

export const fileDownload = (url, HeaderValue) => {
  return axios(
    {
      method: "POST",
      url:`/utils/file/download?${url}`,
      data: {
        headers: HeaderValue,
      },
    }
  )
  .then((response) => {
    return response;
  })
  .catch((err) => {
    return err?.response || err;
  });
};
