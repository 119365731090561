import * as React from 'react';
import PropTypes from 'prop-types';
import { styled as styled_mui } from '@mui/material/styles';
import styled from "styled-components";
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import ArrowForwardOutlined from '@material-ui/icons/ArrowForwardOutlined';
import { useHistory } from "react-router";
import cogoToast from "cogo-toast";
import CommonButton from "../../../atoms/Button/CommonButton";
import {popUpStyles} from "../Constants";
import "./popup.scss";

const BootstrapDialog = styled_mui(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function SaveChangesPopup({ interviewPrescreenData, client_id, prescreen_id, handleSaveChanges, setIsSaveBtnDisable, isSaveBtnDisable, setLoader}) {
  const isValidQuestionCount = interviewPrescreenData?.number_of_questions >= interviewPrescreenData?.time_allotted * 2;
  const [open, setOpen] = React.useState(false);
  const history = useHistory();
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleSave = () => {
    const isQuesTopicNull = interviewPrescreenData?.questions?.filter(
      (item) => !item?.topic
    );
    if(interviewPrescreenData?.questions?.length === 0 ){
      cogoToast.error('Please add QuickScreen test questions')
      return;
    }
    if(interviewPrescreenData?.questions?.length < (interviewPrescreenData?.number_of_questions || 0)){
      cogoToast.error(`Please add atleast ${interviewPrescreenData?.number_of_questions || 0} QuickScreen test questions`)
      return;
    }
    if(isQuesTopicNull?.length > 0){
      cogoToast.error('Please add Quickscreen question topic')
      return;
    }
    if(interviewPrescreenData?.title === '' ){
      cogoToast.error('Please add QuickScreen test name')
      return;
    }
    if(interviewPrescreenData?.number_of_questions === 0 ){
      cogoToast.error('Please add QuickScreen test total number of questions')
      return;
    }
    if(interviewPrescreenData?.cut_off_percentage === 0 ){
      cogoToast.error('Please add QuickScreen test cutoff percentage')
      return;
    }
    if(interviewPrescreenData?.tab_switch_check?.check && interviewPrescreenData?.tab_switch_check?.count === 0 ){
      cogoToast.error('Please add QuickScreen test tab switch count')
      return;
    }
    setIsSaveBtnDisable(true);
    handleSaveChanges(client_id, prescreen_id, interviewPrescreenData);
    setOpen(false);
    setLoader(true);
  }
  return (
    <CustomContainer>
      <span variant="outlined" onClick={handleClickOpen}>
        Complete Setup <ArrowForwardOutlined className="ml-10" />
      </span>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <BootstrapDialogTitle className={isValidQuestionCount ?  "font-18 bold-600" : "lessQstnHeading" } id="customized-dialog-title" onClose={handleClose} sx={popUpStyles.headingStyle}>
          {isValidQuestionCount ? 'Save Changes' : 'Are you sure you want to go ahead?'}
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <CustomBox>
            {isValidQuestionCount ? 
            <Box>
              <Typography className="font-14 bold-400" gutterBottom>
                  Are you sure about saving these changes done in this template?
               </Typography>
            </Box>
            : 
            <Box sx={popUpStyles.contentBox}>
              <Typography sx={popUpStyles.contentStyles}>
                     You have asked less questions than recommended, are you sure you want to go ahead?
               </Typography>
               <Typography sx={popUpStyles.subTextStyles}>(Recommended: Number of Questions = 2x Number of minutes)</Typography>
            </Box>
            }
           
            <Typography gutterBottom>
              {isValidQuestionCount ? 
                    <CommonButton 
                      buttonName={'SAVE'}
                      onClick={handleSave}
                      isDisable={isSaveBtnDisable}
                      styleClassName={'proceed_button_save proceedBtn'}
                   />
               :
               <Box sx={popUpStyles.buttonBox}>
                     <CommonButton 
                       buttonName={'Cancel'}
                       onClick={handleClose}
                       styleObject={popUpStyles.cancelBtn}
                   />
                    <CommonButton 
                       buttonName={'Confirm'}
                       onClick={handleSave}
                       styleObject={popUpStyles.confirmBtnBtn}
                   />
               </Box>
            }
             
            </Typography>
          </CustomBox>
        </DialogContent>
      </BootstrapDialog>
    </CustomContainer>
  );
}


const CustomContainer = styled.div`
    display:inline-block;
    font-family: 'Poppins';
    letter-spacing: 0.005em;
    cursor: pointer;

    .font-18 {
    font-size: 18px;
    }
    .bold-600 {
        font-weight: 600;
    }

`;

const CustomBox = styled.div`
.font-style {
    font-family: 'Poppins';
    letter-spacing: 0.005em;
}

.proceed_button_save {
    width: 150px;
    height: 40px;
    font-size: 14px;
    font-family: 'Poppins';
    font-weight: 600;
    letter-spacing: 0.005em;
    color: white;
    background-color: #123FBC;
    float: right;
    margin: 20px auto 10px auto;
    border-radius: 10px;
}
.proceed_button_save:hover{
  background-color: #123FBC;
  color: white;
}
.fg-black {
    color: #3D3D3D;
}

.font-18 {
    font-size: 18px;
}

.font-14 {
    font-size: 14px;
}

.bold-600 {
    font-weight: 600;
}

.bold-400 {
    font-weight: 400;
}

`;