import { Modal } from "@iv/shared/components/atoms/Modal/Modal.jsx";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import deactivateIcon from "../../../../assets/images/deactivate.svg";
import "./ActivateModal.scss";

const DeactivateModal = ({showModal, handleClose, prescreenActivePositionLinked}) => {

    const Title = () => {
        return (
            <Box className="activate-modal-title">
                    <Box className="modal-head">
                        <Box>
                            <img src={deactivateIcon} width="20px" alt="icon" />
                        </Box>
                        <Typography className="head-text">Deactivation Not Allowed</Typography>
                    </Box>
            </Box>
        )
    }

    const Body = () => {
        return (
            <Box className="activate-modal">
                <Box className="modal-box">
                    <Box className="modal-text">
                        <p>This test is currently linked to the following active positions:</p>
                        {
                            prescreenActivePositionLinked?.map((data)=>{
                                return <Box>
                                    <ol>
                                        <li>{data}</li>
                                    </ol>
                                </Box>
                            })
                        } 
                        <p>To deactivate this test, please remove it from the mentioned active positions or deactivate the positions first.</p>
                    </Box>
                    <Box className="modal-button">
                        <Box className="blue-button" onClick={handleClose}>Ok</Box>
                    </Box>
                </Box>
            </Box>
        )
    }

    return (
        <Modal
            open={showModal}
            onClose={handleClose}
            title={<Title/>}
            body={<Body />}
            width={"520px"}
        />
    )
};

export default DeactivateModal;