import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import CustomizedTables from "./Table";
import styled from "styled-components";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Select from "react-select";
import CreatePrescreenPopup from "../Popups/CreatePrescreenPopup";
import { RenderNoDataForOverAllDetails } from '../../../atoms/NoData/NoDataBox';
import ActivateModal from "./ActivateModal";
import DeactivateModal from "./DeactivateModal";

import "./PrescreenList.scss";
import { PRESCREENING_STATUS } from "../Constants";
const FormSelect = styled(Select)`
    display: inline-block;
    padding: 0;
    border-radius: 4px;
    margin: 0px 20px 0px 20px;
    width: 200px !important;
    align-content: center;

    > div {
      margin-left: 0;
      background-color: #f8f8f8;
      border: 1px solid rgba(0, 0, 0, 0.23);
    }
  `;
  const PrescreenList = ({ 
    roleResponse, 
    prescreenList, 
    client_id, 
    filterList, 
    canAddPrescreen=true , 
    canSelectClient=false, 
    clientList, onSelectClient, 
    canDuplicatePrescreen=true, 
    changeQuickScreenStatus, 
    quickScreenClientId, 
    quickScreenClientName, 
    quickScreenRole, 
    prescreenActivePositionLinked,
    tabValue,
    setTabValue,
  }) => {
  const [roleOptions, setRoleOptions] = useState([]);
  const [role, setRole] = useState();
  const [category, setCategory] = useState();
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [activateTemplateModal, setActivateTemplateModal] = useState({status:false, data:""});
  const [deactivateTemplateModal, setDeactivateTemplateModal] = useState(false);

  const history = useHistory();

  // Function to set single url query param
  const setUrlQueryParam = (paramName, value) => {
    const urlSearchParams = new URLSearchParams(history?.location?.search);
    urlSearchParams?.set(paramName, value?.toString());
    history.replace(`?${urlSearchParams?.toString()}`);
  }

  const roleChangeHandleMain = (option) => {
    const { value } = option;
    // if(value?.length!==0 && roleResponse[value]!==undefined)
    // {
    // const tempArr = [{ label: "Select all", value: "", name: "Select all" }];

    // value && roleResponse[value].forEach(item => {
    //   tempArr.push({ label: item, value: item, name: item });
    // });
    setRole(value || "");
    // setCategory("");
    let prescreenStatus;
    if(tabValue == 0){ 
      prescreenStatus = PRESCREENING_STATUS.APPROVED 
    } else {
       prescreenStatus = PRESCREENING_STATUS.INACTIVE
    }
    filterList(value, "", client_id, prescreenStatus);
    setUrlQueryParam.call(this, "role", value || '');
    // setCategoryOptions(tempArr);
    // }
  }

  const categoryChangeHandleMain = (option) => {
    const { value } = option;
    if (value?.length !== 0)
      setCategory(value);
    filterList(role, value);
  }

  useEffect(() => {
    if(quickScreenRole && quickScreenRole?.length > 0){
      setRole(quickScreenRole);
    }
  }, [quickScreenRole])

  useEffect(() => {
    if(prescreenActivePositionLinked?.length > 0) {
      setDeactivateTemplateModal(true)
    }
  }, [prescreenActivePositionLinked])

  useEffect(() => {
    if (roleResponse) {
      let tempArr = [{ label: "Select all", value: "", name: "Select all" }];
      for (let key in roleResponse) {
        tempArr?.push({ label: key, value: key, name: key });
      }
      setRoleOptions(tempArr);
    }
  }, [])

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  const handleTabChange = (event, newValue) => {
    if(newValue == 0){
      filterList(role, "", client_id ,PRESCREENING_STATUS.APPROVED);
    } else {
      filterList(role, "", client_id ,PRESCREENING_STATUS.INACTIVE);
    }
    setTabValue(newValue);
  };

  const handleClientChange = (selClient) => {
    setRole(null);
    onSelectClient(selClient)
  }

  return (
    <div className="prescreen_list_main_container">

      <Box minHeight="500px" className="main_container">
        <Grid container>
          <Grid item xs={3}>
            <p className="font-16 bold-500">Available Tests</p>
            <p className="font-13 bold-400 fg-gray nmt-20">
              List of QuickScreen templates saved currently.
            </p>
          </Grid>

         {canSelectClient &&
          <Grid item xs={3}>
            <FormSelect
              className="custom-history-input"
              options={clientList}
              placeholder="All Clients"
              onChange={(selClient) => handleClientChange(selClient)}
              value={quickScreenClientId ? {label: quickScreenClientName , value: quickScreenClientName} : ""}
              name="selectCategoryOption"
              isSearchable
            />
          </Grid>}

          <Grid item xs={3}>
            <FormSelect
              className="custom-history-input"
              options={roleOptions}
              placeholder="All Roles"
              onChange={roleChangeHandleMain}
              name="selectRoleOption"
              isSearchable
              value={role ? { label: role, value: role } : ""}
            />

          </Grid>
          
         {canAddPrescreen &&
          <Grid item xs={3} className="create-btn-main">
            <CreatePrescreenPopup roleResponse={roleResponse} roleOptions={roleOptions} defaultRole={role} defaultCategory={category} client_id={client_id} className="font-14 bold-600" />
          </Grid>
         }
        </Grid>
        <Grid>
          {
            prescreenList ?
            <Box>
              <Box borderBottom="1px solid #E3E3E3">
                <Tabs
                  onChange={handleTabChange}
                  value={tabValue}
                  aria-label="Tabs where each tab needs to be selected manually"
                >
                  <Tab className="tab bold-600" label="Active" />
                  <Tab className="tab bold-600" label="Inactive" />
                </Tabs>
              </Box>
              <TabPanel value={tabValue} index={0}>
                <CustomizedTables client_id={client_id} prescreenList={prescreenList} canDuplicatePrescreen={canDuplicatePrescreen} canSelectClient={canSelectClient} clientList={clientList} setActivateTemplateModal={setActivateTemplateModal} changeQuickScreenStatus={changeQuickScreenStatus}/> 
              </TabPanel>
              <TabPanel value={tabValue} index={1}>
                <CustomizedTables client_id={client_id} prescreenList={prescreenList} canDuplicatePrescreen={canDuplicatePrescreen} canSelectClient={canSelectClient} clientList={clientList} setActivateTemplateModal={setActivateTemplateModal} changeQuickScreenStatus={changeQuickScreenStatus}/> 
              </TabPanel>
            </Box>
            : <RenderNoDataForOverAllDetails firstMessage={"No QuickScreen tests"} secondMessage={"created for any role."} lastMessage={"Set template for any role."} callbackTitle="Set template" />
          }

        </Grid>
      </Box>

          {/* Modals */}
          <ActivateModal
            showModal={activateTemplateModal}
            handleClose={()=>setActivateTemplateModal({status:false, data:""})}
            changeQuickScreenStatus={changeQuickScreenStatus}
          />
          <DeactivateModal
            showModal={deactivateTemplateModal}
            handleClose={()=>setDeactivateTemplateModal(false)}
            prescreenActivePositionLinked={prescreenActivePositionLinked}
          />
    </div>
  );
};

export default PrescreenList;
