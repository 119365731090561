import { Modal } from "@iv/shared/components/atoms/Modal/Modal.jsx";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import activateIcon from "../../../../assets/images/activate.svg";
import "./ActivateModal.scss";
import { PRESCREENING_STATUS } from "../Constants";

const ActivateModal = ({showModal, handleClose, changeQuickScreenStatus}) => {

    const handleConfirm = () => {
        changeQuickScreenStatus(showModal?.data, PRESCREENING_STATUS.APPROVED);
        handleClose();
    }

    const Title = () => {
        return (
            <Box className="activate-modal-title">
                    <Box className="modal-head">
                        <Box>
                            <img src={activateIcon} width="20px" alt="icon" />
                        </Box>
                        <Typography className="head-text">Activate Test</Typography>
                    </Box>
            </Box>
        )
    }

    const Body = () => {
        return (
            <Box className="activate-modal">
                <Box className="modal-box">
                    <Box className="modal-text">
                        <p>Please ensure that you have cross checked the configuration and questions, before activating it.</p>
                    </Box>
                    <Box className="modal-button">
                        <Box className="blue-button" onClick={handleConfirm}>Confirm</Box>
                    </Box>
                </Box>
            </Box>
        )
    }

    return (
        <Modal
            open={showModal?.status}
            onClose={handleClose}
            title={<Title/>}
            body={<Body />}
            width={"520px"}
        />
    )
};

export default ActivateModal;